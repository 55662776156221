import React, { useEffect } from "react"
import { Provider, useDispatch, useSelector } from "react-redux"

import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom"

import TomatoRoll from "~/pages/TomatoRoll/TomatoRoll"
import Notification from "~/components/Notification/Notification"
import SignIn from "~/pages/SignIn/SignIn"
import Spaces from "~/pages/Spaces/Spaces"
import Space from "~/pages/Space/Space"
import NewSpace from "~/pages/NewSpace/NewSpace"
import NewOrganisation from "~/pages/NewOrganisation/NewOrganisation"
import InviteToOrganisation from "~/pages/InviteToOrganisation/InviteToOrganisation"
import SettingsProfile from "~/pages/SettingsProfile/SettingsProfile"
import SettingsOrganisation from "~/pages/SettingsOrganisation/SettingsOrganisation"
import SettingsUsers from "~/pages/SettingsUsers/SettingsUsers"
import Dashboard from "~/pages/Dashboard/Dashboard"
import SignUp from "~/pages/SignUp/SignUp"
import NewPassword from "~/pages/NewPassword/NewPassword"
import EditPassword from "~/pages/EditPassword/EditPassword"
import EmailVerification from "./pages/EmailVerification/EmailVerification"
import AcceptInvite from "./pages/AcceptInvite/AcceptInvite"
import NotFound from "./pages/NotFound/NotFound"
import { fetchUser, getUser, getUserFetching, store } from "~/store"
import SpaceChannel from "./channels/SpaceChannel"
import SpacesChannel from "./channels/SpacesChannel"

function AppWithProvider() {
  const dispatch = useDispatch()
  const user = useSelector(getUser)
  const fetchingUser = useSelector(getUserFetching)

  useEffect(() => {
    dispatch(fetchUser())
  }, [])

  if (fetchingUser) return <TomatoRoll />
  if (!user) return notLoggedIn()
  if (user && !user.confirmed) return <EmailVerification />
  return loggedIn(user)
}

function notLoggedIn() {
  return (
    <BrowserRouter>
      <Switch>
        <Route path="/signup">
          <SignUp />
        </Route>
        <Route path="/password/new">
          <NewPassword />
        </Route>
        <Route path="/password/edit/:resetPasswordToken">
          <EditPassword />
        </Route>
        <Route path="/invite/accept/:invitationToken">
          <AcceptInvite />
        </Route>
        <Route path="/">
          <SignIn />
        </Route>
        <Route path="*">
          <NotFound />
        </Route>
      </Switch>
    </BrowserRouter>
  )
}

function loggedIn(user) {
  return (
    <BrowserRouter>
      <SpacesChannel />
      <SpaceChannel />
      <Switch>
        <Route exact path="/">
          {user.space_name ? (
            <Redirect to={`/spaces/${user.space_name}`} />
          ) : (
            <Redirect to="/spaces" />
          )}
        </Route>
        <Route path="/signin">
          <Redirect to="/spaces" />
        </Route>
        <Route path="/password">
          <Redirect to="/spaces" />
        </Route>
        <Route exact path="/settings">
          <Redirect to="/settings/profile" />
        </Route>
        <Route exact path="/settings/profile">
          <SettingsProfile />
        </Route>
        <Route exact path="/settings/organisation">
          {user.organisation_name ? (
            <SettingsOrganisation />
          ) : (
            <Redirect to="/settings/profile" />
          )}
        </Route>
        <Route exact path="/settings/users">
          {user.organisation_name ? (
            <SettingsUsers />
          ) : (
            <Redirect to="/settings/profile" />
          )}
        </Route>
        <Route path="/spaces/new">
          <NewSpace />
        </Route>
        <Route path="/spaces/:spaceName">
          <Space />
        </Route>
        <Route path="/spaces">
          <Spaces />
        </Route>
        <Route path="/dashboard">
          <Dashboard />
        </Route>
        <Route path="/organisations/new">
          {user.organisation_name ? (
            <Redirect to="/spaces" />
          ) : (
            <NewOrganisation />
          )}
        </Route>
        <Route path="/organisations/invite">
          {user.organisation_name && user.admin ? (
            <InviteToOrganisation />
          ) : (
            <Redirect to="/spaces" />
          )}
        </Route>
        <Route path="*">
          <NotFound />
        </Route>
      </Switch>
    </BrowserRouter>
  )
}

export default function App() {
  return (
    <Provider store={store}>
      <Notification />
      <AppWithProvider />
    </Provider>
  )
}
