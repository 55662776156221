import React, { useCallback, useEffect, useMemo, useState } from "react"
import { useSelector } from "react-redux"

import Countdown from "~/components/Countdown/Countdown"

import { formattedDuration } from "~/utils/TimeFormatter"
import Button from "~/components/Button/Button"
import axios from "axios"
import { postEvent } from "~/utils/EventManager"

import "./Timer.scss"
import { setLuxaforLight } from "../../utils/Luxafor"
import { getUser } from "~/store"
import { BREAK_MESSAGES } from "./breakMessages"
import {
  animateTimerFaviconIn,
  setCycleFavicon,
  setTimerFavicon,
} from "../../utils/Favicon"

function Timer(props) {
  const user = useSelector(getUser)
  const { spaceName, timer, onATimerHasEnded } = props
  const [isAnimatedIn, setIsAnimatedIn] = useState(false)

  const updateTitleAndFavicon = useCallback(
    (durationLeft) => {
      if (!durationLeft) {
        document.title = "Pomodoro"
        setCycleFavicon(timer.break ? "break" : "work")
      } else {
        if (isAnimatedIn) setTimerFavicon(timer)
        document.title = formattedDuration(durationLeft)
      }
    },
    [isAnimatedIn]
  )

  useEffect(() => {
    setLuxaforLight(user, timer.break ? "00ff00" : "ff0000")
    animateTimerFaviconIn(timer, 1000).then(() => setIsAnimatedIn(true))

    return () => {
      setLuxaforLight(user, "000000")
      updateTitleAndFavicon()
    }
  }, [setIsAnimatedIn])

  const cancelCurrentTimer = () => {
    axios
      .delete(`/api/spaces/${spaceName}/timers/current`)
      .then((_response) => {
        postEvent(spaceName, "timerCancelled")
        updateTitleAndFavicon()
        props.onCancelled()
      })
  }

  const stringText = useMemo(() => {
    return BREAK_MESSAGES[Math.floor(Math.random() * BREAK_MESSAGES.length)]
  }, [])

  return (
    <div className="Timer">
      <span className="Timer__headline">
        {timer.break ? stringText : "Stay focused for"}
      </span>

      <div className="Timer__countdown">
        <Countdown
          spaceName={spaceName}
          onTick={updateTitleAndFavicon}
          timer={timer}
          onCountDownEnd={onATimerHasEnded}
        />
      </div>
      <Button
        text="Stop timer"
        style={`outline Timer__cycle--${timer.break ? "break" : "work"}`}
        onClick={cancelCurrentTimer}
      />
    </div>
  )
}

Timer.defaultProps = {
  onCancelled: () => {},
}

export default Timer
