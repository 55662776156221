import React from "react"
import Footer from "~/components/Footer/Footer"
import PageContent from "~/components/PageContent/PageContent"
import Heading from "~/components/Heading/Heading"
import "./NotFound.scss"

function NotFound() {
  return (
    <PageContent>
      <div className="LinkToHome">
        <p className="LinkToHome__404">404</p>
        <Heading text="Page Not Found" />
        <a
          href="/"
          title="Click to return to the homepage"
          aria-label="Return to the homepage"
          className="LinkToHome__link"
        >
          Return to the Homepage
        </a>
      </div>

      <Footer style="light" />
    </PageContent>
  )
}

export default NotFound
