import React from "react"
import "./SpaceLinks.scss"

import googleMeetIcon from "./google-meet.svg"
import CopyableLink from "../CopyableLink/CopyableLink"

function SpaceLinks({ hideTitle = true, align = "center", space = {} }) {
  if (!space.google_meet_url) return null

  return (
    <div className="SpaceLinks">
      {hideTitle ? "" : <h4>Links</h4>}
      <div className={`SpaceLinks__links SpaceLinks__links--${align}`}>
        <CopyableLink
          href={space.google_meet_url}
          title="Go to meeting"
          target="_blank"
          rel="noreferrer"
          className="SpaceLinks__link"
        >
          <img src={googleMeetIcon} />
        </CopyableLink>
      </div>
    </div>
  )
}

export default SpaceLinks
