import { calculatePercentage } from "~/utils/TimerUtils"

const faviconElement = document.querySelector('link[rel="icon"]')

// Prepare canvas
const canvas = document.createElement("canvas")
const canvasSize = 64
const centerPoint = canvasSize / 2
const progressRadius = (canvasSize / 2 / 16) * 12 // 2 px margin in an imaginary 16 px wide favicon
canvas.height = canvasSize
canvas.width = canvasSize
const ctx = canvas.getContext("2d")

// Load png favicons to images (so that we can use them as canvas backgrounds)
const workIcon = new Image()
workIcon.src = "/favicon.png"
const breakIcon = new Image()
breakIcon.src = "/favicon-break.png"

export function setCycleFavicon(cycle) {
  faviconElement.href =
    cycle === "break" ? "/favicon-break.png" : "/favicon.png"
}

export function setTimerFavicon(
  timer,
  progressScale = 1 // Used for scaling the timer in
) {
  ctx.clearRect(0, 0, canvasSize, canvasSize)

  // Draw normal favicon as background
  const usedBackground = timer.break ? breakIcon : workIcon
  ctx.drawImage(usedBackground, 0, 0, canvasSize, canvasSize)

  // Draw progress circle
  const circleStartingPoint = -Math.PI / 2
  const fullCircle = 2 * Math.PI
  const progress = calculatePercentage(timer) / 100
  ctx.beginPath()
  ctx.moveTo(centerPoint, centerPoint) // Start the path in the center
  ctx.arc(
    // Add the progress circle fragment to the path
    centerPoint,
    centerPoint,
    progressRadius * progressScale,
    circleStartingPoint + progress * fullCircle,
    circleStartingPoint + fullCircle
  )
  ctx.lineTo(centerPoint, centerPoint) // Move back to the center
  ctx.fillStyle = timer.break ? "#ddf4e9" : "#f5c8c5"
  ctx.fill() // Fill the created path

  faviconElement.href = canvas.toDataURL("image/png")
}

let animateDuration
let animateTimer
let animateStartTime
let animateInCallback

const easeOutCubic = (d) => 1 - Math.pow(1 - d, 3)

export function animateTimerFaviconIn(timer, duration) {
  animateDuration = duration
  animateTimer = timer
  requestAnimationFrame(animateStep)
  return new Promise((resolve) => (animateInCallback = resolve))
}

function animateStep(time) {
  if (!animateStartTime) animateStartTime = time

  const animateTimePassed = time - animateStartTime
  const d = Math.min(1, animateTimePassed / animateDuration) // Get the animation progress [0-1]

  setTimerFavicon(
    animateTimer,
    easeOutCubic(d) // Apply an easing function to the progress to make the animation smooth
  )

  if (d !== 1) {
    requestAnimationFrame(animateStep)
  } else {
    animateInCallback()
    animateDuration = undefined
    animateTimer = undefined
    animateStartTime = undefined
    animateInCallback = undefined
  }
}
