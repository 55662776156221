import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { calculateDurationLeft, calculatePercentage } from "~/utils/TimerUtils"
import { formattedDuration } from "~/utils/TimeFormatter"
import { postEvent } from "~/utils/EventManager"
import User from "~/components/User/User"

import axios from "axios"
import "./DashboardTableRow.scss"
import { getUser, switchCycle } from "~/store"

function DashboardTableRow(props) {
  const user = useSelector(getUser)
  const dispatch = useDispatch()
  const [bgMode, setbgMode] = useState("")
  const [percentage, setPercentage] = useState(0)
  const currentTimer = props.timer
  const [durationLeft, setDurationLeft] = useState(
    currentTimer ? calculateDurationLeft(currentTimer) : 0
  )
  var rowUser = props.user

  const onEnded = () => {
    postEvent(rowUser.attributes.space_name, "timerEnded", {
      timer_id: currentTimer.id,
    })
    if (user.space_name == rowUser.attributes.space_name) {
      dispatch(switchCycle())
    }
  }

  useEffect(() => {
    if (currentTimer == null) return
    setbgMode(`DashboardTableRow--${currentTimer.break ? "break" : "work"}`)
    const interval = setInterval(
      (function updateDuration() {
        const durationLeftNow = calculateDurationLeft(currentTimer)
        setPercentage(calculatePercentage(currentTimer))
        setDurationLeft(durationLeftNow)
        if (durationLeftNow == 0) {
          // registerUsersOnTimer()
          onEnded()
        }
        return updateDuration
      })(),
      1000
    )
    return () => {
      setbgMode("")
      setPercentage(0)
      clearInterval(interval)
      setDurationLeft(0)
    }
  }, [currentTimer])

  return (
    <tr
      className={`DashboardTableRow ${bgMode}`}
      style={{ "--percentage": percentage + "%" }}
    >
      <td>
        <div className="DashboardTableRow__name">
          <User
            key={props.index}
            name={rowUser.attributes.name}
            picture={rowUser.attributes.picture}
            className="DashboardTableRow__picture"
          />
          <span>{rowUser.attributes.name}</span>
        </div>
      </td>
      <td>
        <span className="DashboardTableRow__pomodoros">
          {rowUser.relationships["today_timers"].data.length} pomodoros
        </span>
        <span className="DashboardTableRow__description">Today</span>
      </td>
      <td>
        <span className="DashboardTableRow__pomodoros">
          {rowUser.relationships["week_timers"].data.length} pomodoros
        </span>
        <span className="DashboardTableRow__description">This week</span>
      </td>
      <td>
        {rowUser.attributes.space_name != "" ? (
          <>
            {currentTimer != null && calculateDurationLeft(currentTimer) > 0 ? (
              <span className="DashboardTableRow__duration">
                {formattedDuration(durationLeft)}
              </span>
            ) : (
              "- -"
            )}
          </>
        ) : (
          ""
        )}
      </td>
    </tr>
  )
}

DashboardTableRow.defaultProps = {
  user: null,
  timer: null,
}

export default DashboardTableRow
