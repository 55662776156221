export const BREAK_MESSAGES = [
  "Pause for mindful breathing by sitting comfortably, inhaling deeply through your nose, holding the breath briefly, and then exhaling slowly through your mouth. Repeat this cycle for five minutes to focus your mind, relieve stress, and rejuvenate your body.",
  "Try gentle neck rolls by slowly tilting your head from one side to the other, then rolling it forward and backward to ease tension and enhance flexibility.",
  "Loosen up your shoulders with simple shrugs: lift them toward your ears, hold for a moment, then release down to relieve tension.",
  "Stretch your arms and wrists by extending them in front of you, then gently pulling your fingers back toward your body to counteract the effects of typing and texting.",
  "Relax your eyes by closing them and gently pressing your palms against them for a few moments to reduce strain and fatigue.",
  "Take a moment to hydrate by sipping water to refresh your body and mind.",
  "Stand up and stretch your legs by walking around your space to increase circulation and energy.",
  "Practice gratitude by reflecting on three things you are thankful for to boost your mood and perspective.",
  "Take a moment to breathe deeply by inhaling slowly through your nose, holding your breath briefly, and then exhaling slowly through your mouth to calm your mind and body.",
  "Try a quick meditation by sitting comfortably, closing your eyes, and focusing on your breath for a few minutes to center yourself and reduce stress.",
  "Reflect on your goals by visualizing your next steps and envisioning your success to motivate yourself and maintain focus.",
  "Stretch your back by sitting up straight, twisting gently to one side, holding for a moment, and then twisting to the other side to relieve tension and improve posture.",
  "Relax your jaw by opening your mouth wide, then closing it gently to release tension and reduce clenching.",
  "Practice mindfulness by focusing on your breath, sensations, or surroundings to cultivate awareness and presence in the moment.",
  "Stretch your arms by extending one across your chest, holding it with the opposite hand, then switch sides to help improve flexibility and ease muscle tightness.",
  "For a basic leg stretch, extend one leg forward, keeping it straight, and gently reach toward your toes to stretch your hamstrings, then switch legs to balance out the stretch.",
  "Relieve tension with a simple back stretch: sit or stand, reach your arms overhead, and gently bend forward, letting your arms dangle to stretch your lower back.",
  "Relax your eyes by looking away from your screen, focusing on a distant object for 20 seconds, then returning to your task to reduce eye strain and fatigue.",
  "Relax your eyes by gently looking up, then down, repeating this movement a few times to ease eye strain and enhance focus.",
  "Stay hydrated by pausing to drink a refreshing glass of water, helping you maintain energy and focus throughout the day.",
  "Take a quick walk to stretch your legs and refresh your mind, returning energized and ready to focus.",
  "Find relaxation with Child's Pose by kneeling on the floor, sitting back on your heels, and extending your arms forward, resting your forehead on the floor. Hold this position to gently stretch your back, hips, and shoulders.",
  "Practice deep breathing by inhaling slowly through your nose, holding your breath for a moment, and exhaling slowly through your mouth to calm your mind and body.",
  "Ground yourself in Mountain Pose by standing with feet together, pressing firmly into the ground, and engaging your legs. Roll your shoulders back, lift your chest, and reach your arms overhead or alongside your body, feeling strong and balanced.",
  "Try a seated twist by sitting tall with your legs crossed or extended, placing your right hand on your left knee and your left hand behind you. Inhale deeply to lengthen your spine, then exhale as you gently twist to the left. Hold for a few breaths, then switch sides.",
  "Stretch your neck by gently tilting your head to one side, holding for a few breaths, then switching to the other side. You can also extend one arm alongside your body and gently tilt your head to the opposite side to deepen the stretch.",
  "Relax your shoulders by rolling them up, back, and down in a slow, circular motion to release tension and improve mobility.",
  "Try the yoga pose Downward Dog. Start on all four, then lift your hips upward while straightening your legs, pressing your heels down and hands firmly on the floor. Keep your head between your arms and your spine long, feeling the stretch through your back and hamstrings.",
  "Challenge your balance by carefully standing on a balance board, engaging your core and maintaining a steady posture while shifting your weight back and forth. This exercise will strengthen your stabilizing muscles and improve overall coordination.",
  "Relax and unleash your creativity by doodling freely on paper, letting your thoughts flow and giving your mind a playful break from daily tasks.",
  "Take a moment to sketch, letting your imagination guide the pencil across the page to create images and ideas while providing a soothing mental escape.",
  "Learn to juggle with small items like soft balls or beanbags to improve coordination and focus while having fun and challenging yourself.",
  "Boost your mindset by repeating positive affirmations like, I am capable.",
  "Boost your mindset by repeating positive affirmations like, I handle challenges with grace.",
  "Boost your mindset by repeating positive affirmations like, I am resilient and can overcome any challenge.",
  "Boost your mindset by repeating positive affirmations like, I deserve success and embrace opportunities.",
  "Boost your mindset by repeating positive affirmations like, I trust my intuition and make wise decisions.",
  "Boost your mindset by repeating positive affirmations like, I am surrounded by love and attract positivity.",
  "Boost your mindset by repeating positive affirmations like, I believe in my potential and am always growing.",
  "Boost your mindset by repeating positive affirmations like, I radiate confidence and inner strength.",
  "Boost your mindset by repeating positive affirmations like, I am grateful for the abundance in my life.",
  "Boost your mindset by repeating positive affirmations like, I attract opportunities that align with my goals.",
  "Boost your mindset by repeating positive affirmations like, I am calm, centered, and at peace with myself.",
  "Boost your mindset by repeating positive affirmations like, I am worthy of all the good things that come my way.",
  "Boost your mindset by repeating positive affirmations like, I am beautiful, inside and out.",
  "Alleviate strain from typing by stretching your wrists to keep them relaxed and nimble.",
  "Alleviate strain from typing by flexing your fingers to keep them relaxed and nimble.",
  "Alleviate strain from typing by rotating your forearms to keep them relaxed and nimble.",
]
