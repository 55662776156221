import React, { useEffect, useRef, useState } from "react"

import "./CustomTimerModal.scss"

function CustomTimerModal(props) {
  const [minutes, setMinutes] = useState("")
  const inputRef = useRef()
  useEffect(() => {
    inputRef.current.focus()
  }, [inputRef.current])

  useEffect(() => {
    const listener = (event) => {
      switch (event.key) {
        case "Escape":
          props.onDismiss()
          break
        case "Backspace":
          if (minutes === "") props.onDismiss()
          break
      }
    }

    window.addEventListener("keydown", listener)

    return () => window.removeEventListener("keydown", listener)
  }, [minutes])

  const handleBackdropClick = (event) => {
    if (event.currentTarget === event.target) {
      props.onDismiss()
    }
  }

  const handleChange = ({ currentTarget: { value } }) => {
    setMinutes(value.replace(/[^0-9]/g, ""))
  }

  const handleSubmit = (event) => {
    event.preventDefault()
    props.onCreate(minutes)
  }

  return (
    <form
      onSubmit={handleSubmit}
      className={`CustomTimerModal CustomTimerModal--${
        props.cycleIsBreak ? "break" : "work"
      }`}
      onClick={handleBackdropClick}
    >
      <div className="CustomTimerModal__card">
        <input
          autoComplete="off"
          ref={inputRef}
          required
          className="CustomTimerModal__input"
          name="duration"
          placeholder="??"
          maxLength="2"
          pattern="0?[1-9]|[1-9]\d"
          value={minutes}
          onChange={handleChange}
        />
        <span className="CustomTimerModal__label">minutes</span>
      </div>

      <div className="CustomTimerModal__actions">
        <button
          className="CustomTimerModal__button"
          type="button"
          onClick={props.onDismiss}
        >
          Back
        </button>
        <button
          type="submit"
          className="CustomTimerModal__button CustomTimerModal__button--go"
        >
          Go
        </button>
      </div>
    </form>
  )
}

export default CustomTimerModal
