import React, { useState } from "react"

import "./NotificationBanner.scss"

export default function NotificationBanner({ cycle }) {
  const [permission, setPermission] = useState(Notification.permission)

  if (permission !== "default") return null

  const handleClick = () => {
    Notification.requestPermission().then(setPermission)
  }

  return (
    <div className={`NotificationBanner NotificationBanner--${cycle}`}>
      <div className="NotificationBanner__wrapper">
        <p className="NotificationBanner__description">
          Would you like a <strong>system notification</strong>
          <br /> when the timer ends?
        </p>
        <button className="NotificationBanner__button" onClick={handleClick}>
          Make my choice
        </button>
      </div>

      <p className="NotificationBanner__disclaimer">
        We won't bother you again. If you change your mind you can always change
        <br /> the browser permissions for the pomodoro app.
      </p>
    </div>
  )
}
