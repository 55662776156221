import React, { useEffect, useState } from "react"
import axios from "axios"
import { useSelector } from "react-redux"

import Heading from "~/components/Heading/Heading"
import TimerOption from "~/components/TimerOption/TimerOption"
import CustomTimerModal from "~/components/CustomTimerModal/CustomTimerModal"
import { postEvent } from "~/utils/EventManager"

import "./TimerPicker.scss"
import { getCycle } from "../../store"

function TimerPicker(props) {
  const [showCustomTimerModal, setShowCustomTimerModal] = useState(false)
  const cycle = useSelector(getCycle)
  const cycleIsBreak = cycle === "break"

  useEffect(() => {
    if (showCustomTimerModal) return

    const listener = (event) => {
      // Don't intercept keyboard shortcuts
      const hasModifier =
        event.shiftKey || event.altKey || event.ctrlKey || event.metaKey
      if (hasModifier) return

      if (/[1-9]/.test(event.key)) setShowCustomTimerModal(true)
    }

    window.addEventListener("keydown", listener)
    return () => window.removeEventListener("keydown", listener)
  }, [showCustomTimerModal])

  const minutes = {
    work: [20, 25, 35],
    break: [5, 10],
  }

  const minutesForCycle = cycleIsBreak ? minutes.break : minutes.work

  const createTimer = (minutes) => {
    const params = {
      timer: {
        duration: minutes * 60,
        break: cycleIsBreak,
      },
    }

    axios
      .post(`/api/spaces/${props.spaceName}/timers`, params)
      .then((_response) => {
        postEvent(props.spaceName, "timerCreated")
      })
      .catch((error) => {
        console.log(error)
      })
  }

  return (
    <div className="TimerPicker">
      <Heading
        text={cycleIsBreak ? "Let's take a break for" : "Let's work for"}
      />
      <div className="TimerPicker__list">
        {minutesForCycle.map((minute, index) => {
          return (
            <TimerOption
              minutes={minute}
              key={index}
              cycleIsBreak={cycleIsBreak}
              onClick={createTimer}
            />
          )
        })}
        <TimerOption
          minutes={"??"}
          cycleIsBreak={cycleIsBreak}
          onClick={() => setShowCustomTimerModal(true)}
        />
      </div>

      {showCustomTimerModal && (
        <CustomTimerModal
          cycleIsBreak={cycleIsBreak}
          onDismiss={() => setShowCustomTimerModal(false)}
          onCreate={createTimer}
        />
      )}
    </div>
  )
}

export default TimerPicker
