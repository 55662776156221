import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Redirect } from "react-router-dom"

import UserGroup from "~/components/UserGroup/UserGroup"
import Sidebar from "~/components/Sidebar/Sidebar"
import Timer from "~/components/Timer/Timer"
import Button from "~/components/Button/Button"
import TimerPicker from "~/components/TimerPicker/TimerPicker"
import PageContent from "~/components/PageContent/PageContent"
import PageWithSidebar from "~/components/PageWithSidebar/PageWithSidebar"
import NotificationBanner from "~/components/NotificationBanner/NotificationBanner"

import { useParams } from "react-router-dom"

import "./Space.scss"
import axios from "axios"
import { postEvent } from "~/utils/EventManager"
import {
  getCycle,
  getUser,
  setUser,
  switchCycle,
  getCurrentSpace,
} from "~/store"
import { setSpaceName } from "../../store"
import SpaceLinks from "../../components/SpaceLinks/SpaceLinks"
import { setCycleFavicon } from "../../utils/Favicon"

function Space() {
  const user = useSelector(getUser)
  const dispatch = useDispatch()
  const cycle = useSelector(getCycle)
  const { spaceName } = useParams()
  const currentSpace = useSelector(getCurrentSpace)
  const [redirect, setRedirect] = useState(false)
  const [aTimerHasEnded, setATimerHasEnded] = useState(false)
  const timerIsRunning = Boolean(currentSpace?.current_timer)

  useEffect(() => {
    dispatch(setSpaceName(spaceName))
  }, [])

  useEffect(() => {
    let timerTitleReminderOn = false
    const interval = setInterval(() => {
      if (currentSpace && aTimerHasEnded) {
        if (!currentSpace.current_timer) {
          document.title = timerTitleReminderOn
            ? "Pomodoro timer ended"
            : "Pomodoro"
          timerTitleReminderOn = !timerTitleReminderOn
        }
      }
    }, 3000)
    return () => {
      document.title = "Pomodoro"
      clearInterval(interval)
    }
  }, [currentSpace, aTimerHasEnded])

  useEffect(() => {
    if (timerIsRunning) return

    setCycleFavicon(cycle)

    return () => setCycleFavicon("work")
  }, [cycle, timerIsRunning])

  const leaveCurrentSpace = async () => {
    const response = await axios.put(`/api/users/${user.id}`, {
      user: { space_id: null },
    })

    postEvent(spaceName, "userLeft")
    const userData = response.data["data"]["attributes"]
    dispatch(setUser(userData))
    dispatch(setSpaceName(null))
    setRedirect(true)
  }

  if (redirect) {
    return <Redirect to={"/spaces"} />
  }

  if (!currentSpace || currentSpace.name !== spaceName) {
    return <div />
  }

  return (
    <PageWithSidebar>
      {"Notification" in window && location.search.includes("beta") && (
        <NotificationBanner cycle={cycle} />
      )}
      <PageContent
        className={`Space__page-content--${
          cycle === "break" ? "break" : "work"
        }`}
      >
        <div className="Space">
          {timerIsRunning ? (
            <Timer
              spaceName={spaceName}
              timer={currentSpace.current_timer}
              onATimerHasEnded={setATimerHasEnded}
            />
          ) : (
            <div className="Space__mode">
              <TimerPicker spaceName={currentSpace.name} />
              <Button
                onClick={() => dispatch(switchCycle())}
                style={`outline Space__cycle--${cycle}`}
                text={cycle == "work" ? "Take a break" : "Back to work"}
              />
            </div>
          )}
        </div>
        <div className="Space__actions">
          <UserGroup hideTitle={false} users={currentSpace.users} />
          <SpaceLinks hideTitle={false} space={currentSpace} />
        </div>
      </PageContent>
      <Sidebar
        space={currentSpace}
        style="secondary"
        onSpacePage={true}
        showLeaveButton={true}
        onLeaveButtonClick={leaveCurrentSpace}
      />
    </PageWithSidebar>
  )
}

export default Space
